import React from 'react'
import { Link, PageProps } from 'gatsby'
import { Head, Nav, Footer, FormBusinessContact } from '~/components'
import * as st from '~/assets/styl/BusinessContact.module.styl'
import cn from 'classnames'

const BusinessContact = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} title="Contato comercial - Bauer Postos" />
      <Nav location={location} />
      <main className={st.main}>
        <section className={cn(st.content1364, st.businessContact)}>
          <div className={st.infoContainer}>
            <section className={st.info}>
              <h1>Contato Comercial</h1>
              <p>
                Entre em contato e solicite seu atendimento com nosso time
                comercial. A equipe da Bauer Postos está de prontidão para
                melhor atendê-lo.
              </p>
            </section>
            <Link to="/" className={st.breadcrumb}>
              Voltar
            </Link>
          </div>

          <FormBusinessContact />
        </section>
      </main>
      <Footer />
    </>
  )
}

export default BusinessContact
